export default (to, from, savedPosition) => {
      console.log('scrollBehavior to', to);
      console.log('scrollBehavior from', from);
      console.log('scrollBehavior savedPosition', savedPosition);

      const scrollTarget = document.querySelector('.scrollArea');
      console.log(scrollTarget);
      console.log(scrollTarget.scrollTop);
      if (scrollTarget) {
        if (savedPosition) {
          to.meta.scrollPos = { x: 0, y: 0 };
          // return savedPosition
        } else {
          to.meta.scrollPos = { x: 0, y: 0 };
        }
      }
      return { x: 0, y: 0 };
    }
